<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="manageColor"
            v-model="manageMsj")
                | {{ manageText }}
                v-btn(flat dark @click.native="manageMsj = false") {{ $t('cerrar') }}
        v-dialog(v-if="modal" v-model="modal" max-width="600" persistent)
            v-form
                v-card
                    v-card-text(v-if="preload")
                       | {{ $t('Cargando...') }}
                    template(v-else)
                        v-card-title
                            .btns.btns-right(v-if="!start")
                                v-btn(icon color="secondary" small @click="close")
                                    v-icon close
                            | {{ $t('Validar usuario') }}
                        v-card-text(v-if="!error")
                            v-layout(row wrap)
                                v-flex(xs12 v-if="!start")
                                    p {{ $t(_r('Estas a punto de empezar la validación del usuario %s, al dar clic en empezar el sistema bloqueará cualquier intento de validación adicional.', '%s', current.tel + ' ' +  current.phone)) }}
                                    p {{ $t('Si te sales de esta pantalla o recargas la página, el sistema finalizará la validación con las respuestas que se hayan completado hasta el momento. No podrás volver a validar este usuario.') }}
                                v-flex(xs12 v-else)
                                    v-stepper(v-model="step")
                                        v-stepper-header(style="display:none;")
                                            v-stepper-step(v-for="(s, i) in (1, maxStep)" :key="'sh'+i" :step="s")
                                        v-stepper-items
                                            v-stepper-content.pa-2(v-for="(s, i) in (1, maxStep)" :key="'sc'+i" :step="s")
                                                div.full-width.mx-auto.text-xs-center(v-if="preloadStep")
                                                    v-progress-circular(small indeterminate color="primary")
                                                v-layout(row wrap v-else)
                                                    v-flex(xs12)
                                                        b.d-block.mb-3 {{ step }}/{{ maxStep }} {{ $t('preguntas') }}
                                                        h4 {{ question.label }}
                                                        v-radio-group(v-model="model.answer")
                                                            v-radio(v-for="(answer, val) in answers" :key="val" :value="val" :label="$t(answer)")
                                                    v-flex(xs12)
                                                        .btns.btns-right
                                                            v-btn.primary(v-if="step < maxStep" depressed small :loading="loadingBtn" @click.stop="saveStep") {{ $t('Siguiente') }}
                                                            v-btn.primary(v-else depressed small :loading="loadingBtn" @click.stop="saveStep") {{ $t('Finalizar') }}
                                                            
                        v-card-text(v-else)
                            v-layout(row wrap)
                                v-flex.text-xs-center(xs12)
                                    h3 {{ $t('Ha ocurrido un error al validar este usuario') }} 
                                    p.d-block.mt-2 {{ errorMsj }}
                        v-card-actions(v-if="!start")
                            .btns.btns-right
                                v-btn(color="secondary" small @click="close") {{ $t('Cancelar') }}
                                v-btn(color="primary" v-if="!error" large :loading="loadingBtn" @click.stop="startValidation") {{ $t('Empezar') }}
</template>

<script>

import _m from 'lodash/merge'
import _r from 'lodash/replace'
import fn from 'mixins/fn'

export default {
    mixins: [fn],
    data(){
        return {

            // Vista
            modal     : false,
            loadingBtn: false,
            preload   : false,
            preloadStep: false,

            // Mensajes
            manageMsj  : false,
            manageColor: '',
            manageText : '',

            error: false,
            errorMsj: '',

            // Datos
            modelSchema: {
                answer: ''
            },
            model   : {},
            current : {},
            answers : [],
            question: {},

            // Estado de la validación
            start  : false,
            step   : 0,
            maxStep: 5
        }
    },
    watch: {
        step(){

            this.question = this.getQuestion(this.step)
            this.model    = _m({}, this.modelSchema)
            
            this.fetchStep()
        }
    },
    computed:{
        questions(){
            return [
                {
                    id   : 1,
                    label: this.$t('¿A cuál de los siguientes nombres corresponde el número de teléfono registrado en la plataforma?'),
                    step : 1
                },
                {
                    id   : 2,
                    label: this.$t('¿A cuál de los siguientes rangos de edad corresponde el número de teléfono registrado en la plataforma?'),
                    step : 2
                },
                {
                    id   : 3,
                    label: this.$t(_r('¿De qué ciudad/municipio del estado de %s corresponde el número de teléfono registrado en la plataforma?', '%s', this.current.state)),
                    step : 3
                },
                {
                    id   : 4,
                    label: this.$t(_r('¿A cuál de las siguientes personas usted invito como activista a la red %s en la plataforma?', '%s', this.current.network)),
                    step : 4
                },
                {
                    id   : 5,
                    label: this.$t(_r('¿A cuál de las siguientes personas usted conoce que sus activistas en Wappid invitaron a la red %s?', '%s', this.current.network)),
                    step : 5
                }
            ]
        }
    },
    methods: {

        getQuestion(step){
            return this.questions.find(q => q.step == step)
        },
        
        _r(...args){
            return _r(...args)
        },

        async open(data){

            this.error   = false
            this.preload = true
            this.step    = 0
            this.start   = false
            this.modal   = true
            this.current = _m({}, data)
            this.model   = this.modelSchema

            let validate = await this.validate()
            
            if(validate){
                this.showValidationError(validate)   
            }

            this.preload = false
        },
        close(){
            this.step       = 0
            this.current    = {}
            this.loadingBtn = false
            this.modal      = false
        },
        validate(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.post('/callcenter/validate?user=' + this.current.uuid).then((r) => {

                        let data = r.data
                        resolve(data)

                    }).catch(() => {
                        resolve(false)
                    })
                })
            })
        },
        showValidationError({response, message}){
            if(!response){
                this.error = true
                this.errorMsj = message
            }
        },
        async startValidation(){

            this.loadingBtn = true
            
            let validate = await this.validate()
            if(validate){
                this.showValidationError(validate)   
            }

            if(!validate || validate.response){
                this.start = true
            }

            this.loadingBtn = false
        },
        fetchStep(){

            this.answers = []
            this.preloadStep = true

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/callcenter/question', {
                        params: {
                            user     : this.current.uuid,
                            question : this.question.id
                        }
                    }).then((r) => {

                        this.preloadStep = false
                        let data = r.data
                        if(data.response){
                            this.answers = data.data
                        }

                        resolve()

                    }).catch(() => {
                        this.preloadStep = false
                        resolve()
                    })
                })
            })
        },

        saveStep(){

            this.loadingBtn = true
            let params      = _m({}, this.model)
            params.question = this.question.id || ''
            params.user     = this.current.uuid

            this.$api(this, (xhr) => {
                xhr.post('/callcenter/step', this.$qs.stringify(params)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.manageMsj  = true

                    if(data.response){

                        this.manageColor = 'success'
                        this.manageText  = data.message
                        this.manageModal = false

                        if(this.step >= this.maxStep){
                            this.$emit('success')
                            this.close()
                        }else{
                            this.step++
                        }

                    }else{
                        this.manageColor = 'error'
                        this.manageText = data.message
                    }

                }).catch(() => {
                    this.loadingBtn = false
                    this.catchError('manage')
                })
            })
        }
    }
}

</script>
